/* eslint-disable @typescript-eslint/interface-name-prefix */

declare global {
  interface Window {
    dataLayer: DataLayerActions;
  }
}

export type DataLayerActions = {
  push: (data: DataLayerProps) => void;
}

export type DataLayerProps = {
  event: 'ga_event_body_blog' | 'ga_event_scroll_blog';
  params: {
    section: string;
    sub_section: string;
    element_action?:
      | 'click button'
      | 'submit'
      | 'click banner'
      | 'click video'
      | 'click scroll'
      | 'click popup';
    element_name?: string;
    c_page: string;
    redirect_url?: string | 'null';
    segment?: string;
    sub_segment?: string | 'null';
  };
}

export const useDataLayer = () => {
  const sendDataLayer = (data: DataLayerProps) => {
    return window.dataLayer?.push(data)
  }

  return { sendDataLayer }
}
