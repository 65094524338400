import React, { useEffect, useRef, useState } from 'react'
import { PageProps } from 'gatsby'
import { format, parseISO } from 'date-fns'
// Os imports comentados abaixo, referem-se aos formulários que foram ocultados até segunda ordem.
import Layout from 'src/components/Layout'
import BreadCrumb from 'src/components/Breadcrumb'
import BlogArticle from 'src/components/BlogArticle'
import AddThis from 'src/components/AddThis'
import ScrollButton from 'src/components/ScrollButton'
// import Newsletter from 'src/components/Newsletter'
import LoadingIndicator from 'src/components/LoadingIndicator'
import SEO from 'src/components/Seo'

import useScroll from 'src/hooks/useScroll'

import isVisible from 'src/utils/isVisible'

import { SideBar /* NewsletterContent */ } from 'src/styles/layout'
import { Banner } from './style'

type Article = {
  id: string;
  slug: string;
  title: string;
  segment_name: string;
  category_name: string;
  subcategory_name: string;
  introduction: string;
  grid_image_url: string;
  published_at: string;
  updated_at: string;
}

type CategoryProps = {
  pageContext: {
    segment: string;
    segmentSlug: string;
    articles: Article[];
  };
} & PageProps

const SegmentTemplate = ({ pageContext }: CategoryProps) => {
  const scroll = useScroll(300)
  const loadingRef = useRef<SVGSVGElement>(null)
  const limit = 8
  const [ offset, setOffset ] = useState(limit)
  const [ loadingVisible, setLoadingVisible ] = useState(true)
  const quantArticles = pageContext.articles.length

  const breadcrumbPath = [
    {
      text: 'Blog',
      link: '/',
    },
    {
      text: pageContext.segment,
      link: `/segmento/${pageContext.segmentSlug}`,
    },
  ]

  useEffect(() => {
    if (isVisible(loadingRef.current) && (offset <= quantArticles)) {
      setOffset(offset + limit)
      setLoadingVisible(false)
    } else if (offset > quantArticles) {
      setLoadingVisible(false)
    } else {
      setLoadingVisible(true)
    }
  }, [ scroll ])

  return (
    <Layout>
      <SEO
        title={`${pageContext.segment} · Blog do Inter`}
        url={`${process.env.BASE_URL}/segmento/${pageContext.segment}/`}
        meta={[
          {
            name: 'description',
            content: `Confira os principais posts sobre o segmento ${pageContext.segment}`,
          },
          {
            property: 'og:title',
            content: `${pageContext.segment} · Blog do Inter`,
          },
          {
            property: 'og:description',
            content: `Confira os principais posts do segmento ${pageContext.segment}`,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}/segmento/${pageContext.segment}`,
          },
          {
            property: 'og:site_name',
            content: 'Blog do Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <h1 className='sr-only'>{pageContext.segment} · Blog do Inter</h1>

      <Banner className='d-flex'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
              <h2 className='fs-24 lh-28 fw-600 text-white'>{pageContext.segment}</h2>
            </div>
          </div>
        </div>
      </Banner>

      <section
        id='compartilhe'
        className='d-flex bg-grayscale--100 py-3 justify-content-center align-items-center h-breadcrumb'
      >
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-lg-9'>
              <BreadCrumb path={breadcrumbPath} />
            </div>
            <div className='col-6 col-lg-3 text-right px-0 pr-lg-2'>
              <AddThis />
            </div>
          </div>
        </div>
      </section>

      <section id='artigos-do-blog' className='pt-4 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='row'>
                {
                  pageContext.articles.slice(0, offset).map((article: Article) => {
                    return (
                      <div key={article.id} className='col-12 col-md-6'>
                        <BlogArticle
                          link={`/${article.slug}/`}
                          image={article.grid_image_url}
                          category={article.category_name}
                          subCategory={article.subcategory_name}
                          title={article.title}
                          date={format(parseISO(article.published_at), 'dd/MM/yyyy')}
                          description={article.introduction}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div className='d-flex justify-content-center'>
                { loadingVisible && <LoadingIndicator ref={loadingRef} /> }
              </div>
            </div>
            <div className='d-none d-lg-block col-lg-4 col-xl-3 offset-xl-1 mt-3'>
              <SideBar>
                <div>
                  {/* <NewsletterContent className='w-100'>
                    <span className='titulo fs-22 lh-25 fw-600 mb-3 text-white'>Assine nossa newsletter</span>
                    <p className='fs-14 lh-17 text-white'>
                      Receba conteúdos completos sobre investimentos, educação financeira e novos produtos
                    </p>
                    <Newsletter
                      name='side-bar'
                      category={pageContext.segment}
                    />
                  </NewsletterContent> */}
                  <ScrollButton position='relative' />
                </div>
              </SideBar>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SegmentTemplate
