import React from 'react'
import ArrowRight from '@interco/icons/orangeds/MD/arrow-right'
import { useLocation } from '@reach/router'

import Tag from 'src/components/Tag'

import BlogArticleStyle from './style'
import { Theme } from 'src/types/Theme'
import { green, orange } from 'src/styles/colors'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

type BlogArticleProps = {
  link: string;
  image: string;
  category: string;
  subCategory?: string;
  title: string;
  date: string;
  description: string;
  altImage?: string;
  urlLink?: string;
  theme?: Theme;
}

const BlogArticle = ({ link, image, category, subCategory, title, date, description, altImage, urlLink, theme = 'pf' }: BlogArticleProps) => {
  const splitDescription = description.split(' ')
  const result = splitDescription.slice(0, 15)
  const newResult = result.toString()
  const finalDescription = newResult.split(',').join(' ')
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()
  const isPJ = theme === 'pj'

  const dataLayerExtract = () => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: location.pathname === '/' ? 'Dobra_02' : title,
        sub_section: location.pathname === '/' ? category : 'Assuntos relacionados',
        element_action: 'click banner',
        element_name: title,
        c_page: location.href,
        redirect_url: `${location.href}${link}`,
        segment: location.pathname === '/' ? category : category,
        sub_segment: location.pathname === '/' ? 'null' : subCategory,
      },
    })
  }

  return (
    <BlogArticleStyle className='text-decoration-none my-3 d-block' to={link} onClick={dataLayerExtract}>
      <figure className='m-0'><a href={urlLink}><img loading='lazy' src={image} alt={altImage} title={title} /></a></figure>
      <div className='bg-white position-relative pt-3 px-4'>
        <div className='d-flex align-items-center'>
          <Tag className='mr-3' theme={theme}>{category}</Tag>
          {subCategory && <Tag color='gray' theme={theme}>{subCategory}</Tag>}
        </div>
        <span className='titulo d-block fs-16 lh-20 lh-lg-25 fs-xl-20 fw-600 text-grayscale--500 mt-3 mb-2'>
          {title}
        </span>
        <span className='fs-12 lh-15 fw-700 text-grayscale--300 d-block mb-lg-3'>
          <time dateTime='2020-11-19 20:00'>{date}</time>
        </span>
        <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 text-grayscale--400 d-none d-lg-block'>
          {finalDescription + '...'}
        </p>
        <span className={`fs-14 ${isPJ ? 'text-green--500' : 'text-orange--base'} d-none d-lg-block text-lg-right`}>
          Leia mais
          <ArrowRight width={21} height={21} color={isPJ ? green[500] : orange.base} className='ml-2' />
        </span>
      </div>
    </BlogArticleStyle>
  )
}

export default BlogArticle
