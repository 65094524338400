import styled from 'styled-components'
import { Link } from 'gatsby'
import breakpoints from 'src/styles/breakpoints'

export default styled(Link)`

  &:hover {

    figure {

      img {
        transform: scale(1.1);
      }
    }
  }

  h3 {

    @media(min-width: ${breakpoints.lg}) {
      height: 75px;
      overflow: hidden;
    }
    @media(min-width: ${breakpoints.xl}) {
      height: 77px;
      overflow: hidden;
    }
  }

  p {

    @media(min-width: ${breakpoints.lg}) {
    min-height: 65px;
    }
  }

  figure {
    overflow: hidden;

    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
    }
  }

  > div {
    border-radius: 16px 16px 0 0;
    margin-top: -50px;
  }

  .titulo {
    font-family: 'Sora';
  }
`
