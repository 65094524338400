import React, { ReactNode } from 'react'
import { Theme } from 'src/types/Theme'

import TagStyle from './style'

type TagProps = {
  color: string;
  children: ReactNode;
  className?: string;
  theme?: Theme;
}

const Tag = ({ color, children, className, theme }: TagProps) => {
  return (
    <TagStyle className={className} color={color} theme={theme}>
      {children}
    </TagStyle>
  )
}

Tag.defaultProps = {
  color: 'orange',
}

export default Tag
