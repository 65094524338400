import styled, { css } from 'styled-components'
import { grayscale, green, orange, white } from 'src/styles/colors'
import { Theme } from 'src/types/Theme'

type TagStyleProps = {
  color: string;
  theme: Theme;
}

export default styled.span<TagStyleProps>`
  padding: 2px 4px;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  border-radius: 4px;

  ${({ color }: TagStyleProps) => {
    switch (color) {
      case 'orange':
        return css`
          background-color: ${orange.base};
          color: ${white};
        `
      case 'gray':
        return css`
          background-color: ${grayscale[100]};
          color: ${orange.base};
        `
      case 'white':
        return css`
          background-color: ${white};
          color: ${orange.base};
        `
    }
  }}

  ${({ theme }: TagStyleProps) => theme === 'pj' && css`
    background: ${green[500]};
    color: ${white};
  `}

`
